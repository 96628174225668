import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import LinkBlock from '../../components/LinkBlock/linkBlock';
import SEO from '../../components/SEO/seo';

const InRainbowsAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/tools" noheader notopmargin >
        <SEO lang="ru" title={'In Rainbows'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'In Rainbows', 'Radiohead']} description="Почему In Rainbows считается одним из лучших альбомов Radiohead. Как я полюбил Radiohead и начал понимать их ценность.">
            <link rel="alternate" href={`${site.siteMetadata.siteUrl}/tools/fedro`} hrefLang="en" key="en"/>
        </SEO>
        <HeroWithPhotoAndHeader blHeader backto="/tools" desc="Create tasks faster. Anytime. Anywhere." imageURL={'telegramTasks.png'} heading="Telegram Bot Notion Helper"/>

        <Section heading="Source">
            <LinkBlock href="https://figma.com" heading="The Design Productivity Blueprint" para="Figma content"/>
        </Section>

        <Section heading="The Idea">
            <Paragraph>If you're familiar with my other project — <LinkText href="/tools/dashboard">Notion Dashboard</LinkText>, you questioned how I will maintain this complicated system. Especially, when I without a laptop.</Paragraph>
            <Paragraph>Of course, you can use the mobile version of Notion, but if you're used to it, you know how it's slow. You can use your notebook, write down your tasks, and when you're with your computer, just move them into Notion.</Paragraph>
            <Paragraph>But rewriting, it's so annoying!</Paragraph>
            <Paragraph>So I came with the idea to make a telegram bot, which will write the tasks into my main Notion table, and when I with my computer I will label them and divide them into projects.</Paragraph>
            <Paragraph>And it's so much faster! You just text the bot and your task is created!</Paragraph>
        </Section>

        <Section heading="So what I used to create the bot?">
            <Paragraph>First, it's a Telegraf framework for javascript. I know javascript, so it will be so much easier to create the bot.</Paragraph>
            <Paragraph>Second, it's a new Notion API, in which there is a method that helped me to update my big Tasks table.</Paragraph>
        </Section>

        <Section heading="Create a Notion API and share your table.">
            <Paragraph>Now you need to get to know what is the id of your table. You just need to copy-paste the URL to your table and get an id from the URL.</Paragraph>
            <Paragraph>Then, create a bot using the Bot father. Get the hash and create a sample request using curl or postman.</Paragraph>
            <Paragraph>Write a simple script and text the bot. You will get your Id. Create a constant with your Id. We need this to make your bot personal, so no one can write to the bot, create their tasks into your table.</Paragraph>
            <Paragraph>Create other constants.</Paragraph>
        </Section>

        <Section heading="And write the final script.">
            <Paragraph>Now you need to get to know what is the id of your table. You just need to copy-paste the URL to your table and get an id from the URL.</Paragraph>
            <Paragraph>Then, create a bot using the Bot father. Get the hash and create a sample request using curl or postman.</Paragraph>
            <Paragraph>Write a simple script and text the bot. You will get your Id. Create a constant with your Id. We need this to make your bot personal, so no one can write to the bot, create their tasks into your table.</Paragraph>
            <Paragraph>Create other constants.</Paragraph>
        </Section>

        <Section heading="Hosting">
            <Paragraph>Now you need to host it. You can use Amazon, Google, or even host it on your own machine (or on Raspberry Pi, if you have one). Chances you're thinking about Heroku, but it's not a great solution, it can't keep the process on more than 5 minutes.</Paragraph>
        </Section>

        <Section heading="Conclusion">
            <Paragraph>And now you have a great bot, that made your life a little easier.</Paragraph>
            <Paragraph>You can customize it, add more properties to send to Notion. Read the documentation and see what you can send to Notion.</Paragraph>
            <Paragraph>Thank you! Hope it helps you!</Paragraph>
        </Section>

    </Layout>
  ); };

export default InRainbowsAlbumPage;
