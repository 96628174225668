import React from 'react';
import HeroSection from '../Blocks/HeroSection/heroSection';
import PlaceImage from '../Blocks/PlaceImage/placeImage';
import Header from '../Header/header';

import './heroWithPhotoAndHeader.css';

interface HeroWithPhotoAndHeaderProps{
  imageURL: string;
  heading: string;
  desc: string;
  backto?: string;
  type?: "wt" | "bl"
  blHeader?: boolean;
}

const HeroWithPhotoAndHeader = ({ imageURL, heading, desc, backto, type, blHeader }: HeroWithPhotoAndHeaderProps) => {
  return (

        <div className="HeroWithPhotoAndHeader">
            <PlaceImage nozoom alt={heading} filename={imageURL} imageClass="HeroWithPhotoAndHeader-image"/>
            <Header type={blHeader ? "bl" : "wt"} backTo={backto} />
            <HeroSection type={type} animationType="noanima" heading={heading} desc={desc}/>
        </div>
  );
};

HeroWithPhotoAndHeader.defaultProps = {
  type: "wt",
  blHeader: false,
}

export default HeroWithPhotoAndHeader;
