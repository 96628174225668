import React from 'react'

const LinkIcon = () => <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.8445 40.3295C31.7395 40.3295 40.5717 31.4973 40.5717 20.6023C40.5717 9.7072 31.7395 0.875 20.8445 0.875C9.94939 0.875 1.11719 9.7072 1.11719 20.6023C1.11719 31.4973 9.94939 40.3295 20.8445 40.3295Z" stroke="#6A6A6A" stroke-width="1.5"/>
<path d="M22.4323 19.0146C21.59 18.1727 20.4479 17.6997 19.257 17.6997C18.0661 17.6997 16.9239 18.1727 16.0817 19.0146L12.9053 22.1899C12.063 23.0322 11.5898 24.1746 11.5898 25.3657C11.5898 26.5569 12.063 27.6993 12.9053 28.5416C13.7476 29.3839 14.89 29.857 16.0811 29.857C17.2723 29.857 18.4147 29.3839 19.257 28.5416L20.8446 26.9539" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.2568 22.1899C20.0991 23.0318 21.2413 23.5047 22.4322 23.5047C23.6231 23.5047 24.7652 23.0318 25.6075 22.1899L28.7838 19.0146C29.6261 18.1723 30.0993 17.0299 30.0993 15.8387C30.0993 14.6475 29.6261 13.5052 28.7838 12.6629C27.9415 11.8206 26.7992 11.3474 25.608 11.3474C24.4168 11.3474 23.2744 11.8206 22.4322 12.6629L20.8445 14.2505" stroke="#6A6A6A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




export default LinkIcon;
