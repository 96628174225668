import React from 'react'
import H3 from '../Blocks/H3/h3'
import Paragraph from '../Blocks/Paragraph/paragraph'
import LinkIcon from './icons/link.tsx'
import {LinkBlockContainer, LinkBlockWraper, LinkBlockTextContainer, LinkBlockTextHeading, LinkBlockTextPara} from './linkBlock.module.css'

interface LinkBlockProps {
    heading: string;
    para: string;
    href: string;
}

const LinkBlock = ({heading, para, href}: LinkBlockProps) => {
    return (
        <a className={LinkBlockContainer} href={href} target="_blank" rel="noreferrer noopener">
            <div className={LinkBlockWraper}>
                <LinkIcon/>
                <div className={LinkBlockTextContainer}>
                    <H3 className={LinkBlockTextHeading}>{heading}</H3>
                    <Paragraph className={LinkBlockTextPara}>{para}</Paragraph>
                </div>
            </div>
        </a>
    )
}

export default LinkBlock
