import React, { HTMLAttributes } from 'react';

import './linkText.css';

interface LinkTextProps extends HTMLAttributes<HTMLElement>{
  href: string;
}

const LinkText = ({ children, href }: LinkTextProps) => {
  return (
        <a target="_blank" rel="noopener noreferrer" href={href} className="LinkText">
            {children}
        </a>
  );
};

export default LinkText;
